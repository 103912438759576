





















































































































import { defineComponent, onMounted, provide, Ref, ref, toRef, watch } from '@vue/composition-api'
import { useState, useActions } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import moment from 'moment'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import { MSRActionPlanDTO } from '@/dto/backend-response/msrActionPlansDTO'
import router from '@/router'
import utils from '@/shared/mixins/utils'
import msrActionplanQuery from '@/shared/queries/msrActionplanQueries'
import variables from '@/shared/variables'
import { TranslateResult } from 'vue-i18n'
import MSRActionPlan from '@/components/msr-assessments/actionplan/MSRActionPlan.vue'
import actionplansData from '@/assets/data/action-plan-data.json'
import generalData from '@/assets/data/general-data.json'
import { Status, UserRole } from '@/shared/enum/general-enum'

export default defineComponent({
    components: { Table, MSRActionPlan },
    props: {
        statefilter: {
            type: Number,
            required: false,
        },
        loggedInUser: {
            type: String,
        },
        validationMode: {
            default: false,
        },
    },
    setup(props) {
        const actionPlans = ref([])
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const loading = ref(false)
        const { role } = useState(['role'])
        const noDataMessage: Ref<TranslateResult> = ref('')
        const { menuOpen } = useState(['menuOpen'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const tableCmp = ref(null)
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const displayActionPlan = ref(false)
        const lastRecordNo = ref(null)
        const actionPlanRecordNo = ref(0)
        const searchValue = ref('')
        const actionplanId = ref(null)
        const actionPlanDefaultFilters = ref({})
        const status: Ref = ref([])
        const totalPages = ref(0)
        const alertColor = ref(router.app.$route.query.alertColor)
        const validationMenu = ref(false)
        const actionplanTitleInfomationOP = ref(null)
        const firstRecordNo = ref(null)

        // Make validation mode available to children in sidebar
        provide('VIEW_VALIDATION_MODE', toRef(props, 'validationMode'))

        // open information overlay panel
        const toggleInformation = (event: object) => {
            actionplanTitleInfomationOP.value.toggle(event)
        }

        const setDefaultFilters = () => {
            actionPlanDefaultFilters.value = {}
            if (alertColor.value)
                actionPlanDefaultFilters.value['alertColorName'] = alertColor.value
            if (props.loggedInUser) actionPlanDefaultFilters.value['fowner'] = [props.loggedInUser]
        }

        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('NUMBER_SHORT', language.value),
                    sort: true,
                    fieldName: 'refId',
                    style: 'min-width: 210px;',
                    alert: true,
                },
                {
                    header: t('MSR_ACTION_PLAN_TABLE_COLUMN_MSR_NUMBER', language.value),
                    naturalCompare: true,
                    fieldName: 'msrNumber',
                    style: 'width: 200px;',
                },
                {
                    header: t('ACTION_PLAN_TABLE_COLUMN_DESCRIPTION', language.value),
                    sort: true,
                    fieldName: 'description',
                    limit: 200,
                    style: 'min-width: 400px;',
                },
                {
                    header: t('ACTION_PLAN_TABLE_COLUMN_SUBCATEGORY', language.value),
                    naturalCompare: true,
                    fieldName: 'fmsrSubCategory',
                },
                {
                    header: t('ACTION_PLAN_TABLE_COLUMN_STATE', language.value),
                    sort: true,
                    fieldName: 'status',
                },
                {
                    header: t('ACTION_PLAN_TABLE_COLUMN_START_DATE', language.value),
                    sort: true,
                    fieldName: 'fstartDate',
                    type: 'date',
                },
                {
                    header: t('ACTION_PLAN_TABLE_COLUMN_END_DATE', language.value),
                    sort: true,
                    fieldName: 'fendDate',
                    type: 'date',
                },
                {
                    header: t('ACTION_PLAN_TABLE_COLUMN_DAYS_OPEN', language.value),
                    sort: true,
                    fieldName: 'daysOpen',
                },
                {
                    header: t('ACTION_PLAN_TABLE_COLUMN_OWNER', language.value),
                    sort: true,
                    fieldName: 'fowner',
                },
            ]
        }

        const openActionPlanPopup = (id) => {
            displayActionPlan.value = true
            actionplanId.value = id
            actionPlanRecordNo.value = actionPlans.value.find(
                (actionplan) => actionplan.id == id
            ).no
            // check for the `tableCmp` reference existance
            if (!tableCmp.value) {
                return
            }
            // set last record no
            lastRecordNo.value = utils.getLastRecord(tableCmp.value.copyOfTableData)
            // set first record no
            firstRecordNo.value = tableCmp.value.copyOfTableData[0].no
        }

        // convert id values to name values
        const convertIdToNames = (state: number) => {
            // if the state is inprogress, apply ACTIONPLAN_STATUS_INPROGRESS language change only for action plans
            if (state === Status.inprogress) {
                return t('ACTIONPLAN_STATUS_INPROGRESS', language)
            } else {
                const stateVal = utils.idToName(generalData.STATUS, state)
                return t(stateVal, language.value)
            }
        }
        // updating actionPlans array with additional fields and setting description content
        const toTableFormat = (aps: Array<MSRActionPlanDTO>) => {
            actionPlans.value = []
            aps.map((actionPlan) => {
                let ap = {
                    id: actionPlan.id,
                    no: null,
                    fmsrSubCategory: '',
                    fowner: '',
                    fstartDate: '',
                    fendDate: '',
                    daysOpen: 0,
                    msrAssessmentId: 0,
                    status: null,
                    color: '',
                    msrNumber: '',
                    alertColorName: '',
                    info: '',
                    infoKey: '',
                }
                ap = Object.create(actionPlan)
                ap.no = utils.padLeft('000', actionPlan.order)
                ap.fmsrSubCategory = actionPlan.msrAssessment.msr.subCategory
                ap.fowner =
                    actionPlan.owner && actionPlan.owner.firstName + ' ' + actionPlan.owner.lastName
                //  using moment to convert date since Safari browser doesn't support new Date(dd-mm-yyyy)
                ap.daysOpen = utils.calculateDaysOpen(moment(actionPlan.endDate).toDate())
                ap.fstartDate = actionPlan.startDate
                ap.fendDate = actionPlan.endDate
                ap.status = convertIdToNames(Number(actionPlan.state))
                ap.msrAssessmentId = actionPlan.msrAssessment.id
                ap.msrNumber = actionPlan.msrAssessment.msr.msrNumber

                const alertsMenuType: string = props.validationMode ? 'menu-validation' : 'not-validation-mode'
                ap.color = utils.getAlertInfo(alertsMenuType, actionPlan).color
                ap.info = utils.getAlertInfo(alertsMenuType, actionPlan).info
                ap.infoKey = utils.getAlertInfo(alertsMenuType, actionPlan).infoKey
                ap.alertColorName = utils.getAlertInfo(alertsMenuType, actionPlan).alertColorName
                actionPlans.value.push(ap)
            })
            // set record counts
            filteredRecordCount.value = actionPlans.value.length
        }

        const getActionPlans = async (params) => {
            loading.value = true
            const pageNum = params.pageNum
            const query = `
                query{
                    msrActionPlans(perPage: ${variables.LIST_ITEMS_PER_PAGE}, pageNum: ${pageNum}, filter: {State: [${status.value}]}){
                        pageInfo{
                            totalPages
                            totalCount
                        }
                        items{
                            ${msrActionplanQuery.ACTION_PLANS_LIST}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(query)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving msr actionplans')
            }
            if (result) {
                const actionPlansResult = result.data.msrActionPlans.items
                actionPlansResult.value = actionPlansResult
                totalPages.value = result.data.msrActionPlans.pageInfo.totalPages
                filteredRecordCount.value = result.data.msrActionPlans.pageInfo.totalCount
                actionPlansResult.value.sort((a, b) => (a.refId > b.refId ? 1 : -1))
                // updating actionplan with additional fields and setting description content
                toTableFormat(actionPlansResult.value)
            }
        }

        // go to action plan record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `actionPlans` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // find the actionplan id of the record
                actionplanId.value = actionPlans.value.find(
                    (actionplan) => actionplan.no === record.no
                ).id
                actionPlanRecordNo.value = record.no
            }
        }

        // update list after assessment is created/updated
        const updateRecords = async () => {
            await getActionPlans({ pageNum: 1 })
        }

        // set no data message based on logged in user roles
        const setNoDataMessageForRoles = () => {
            let message
            switch (role.value) {
                case UserRole.EMPLOYEE:
                    message = 'NO_ACTION_PLANS_TABLE_DATA_AVAILABLE_FOR_USER_MESSAGE'
                    break
                case UserRole.VALIDATOR:
                    message = 'NO_VALIDATION_ACTION_PLANS_TABLE_DATA_AVAILABLE_FOR_USER_MESSAGE'
                    break
                default:
                    'No role found'
                    break
            }
            noDataMessage.value = t(message, language.value)
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // set filters count
        const setCount = (count) => {
            filterCount.value = count
        }

        // update table records count
        const updateRecordCount = (recordsCount) => {
            filteredRecordCount.value = recordsCount
        }

        // set searched records count
        const searchCount = (count) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close actionplan sidebar if it's open
                    if (displayActionPlan.value) {
                        displayActionPlan.value = false
                    }
                }
            })
        }

        // set values based on query params
        const setQueryParamConfig = () => {
            // active menu based on menu headers
            validationMenu.value = props.validationMode
            if (validationMenu.value) {
                status.value = [Status.submitted, Status.approved, Status.declined]
            } else {
                status.value = []
            }
            // setting filter values from query params
            alertColor.value = router.app.$route.query.alertColor
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(language, () => {
            setTableHeaders()
            toTableFormat(actionPlans.value)
            setNoDataMessageForRoles()
        })

        // watch for loggedInUser router prop which is coming from tiles page
        watch(
            () => props.loggedInUser,
            () => {
                getActionPlans({ pageNum: 1 })
            }
        )

        watch(
            () => router.app.$route.query,
            async () => {
                setQueryParamConfig()
                await getActionPlans({ pageNum: 1 })
                setDefaultFilters()
            },
            {
                immediate: true,
            }
        )

        onMounted(async () => {
            closeSidebarEsc()
            setTableHeaders()
            setNoDataMessageForRoles()
        })

        return {
            t,
            moment,
            language,
            utils,
            tableHeaders,
            actionPlans,
            loading,
            variables,
            role,
            noDataMessage,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            menuOpen,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            updateRecordCount,
            tableCmp,
            openActionPlanPopup,
            displayActionPlan,
            lastRecordNo,
            actionPlanRecordNo,
            goToRecord,
            searchValue,
            searchCount,
            getActionPlans,
            actionplansData,
            actionplanId,
            actionPlanDefaultFilters,
            totalPages,
            updateRecords,
            validationMenu,
            actionplanTitleInfomationOP,
            toggleInformation,
            firstRecordNo,
        }
    },
})
